<template>
  <div class="container">
    <el-card shadow="always" style="width:100%;">
      <el-form style="width:50%;" label-width="120px" :label-position="labelPosition" size="small" :model="formModel">
        <el-row>
          <el-col>
            <el-form label-width="120px" :label-position="labelPosition" size="small" :model="query">
              <el-form-item prop="title" label="图书ISBN(编号)">
                <el-row>
                  <el-col :span="12">
                    <el-input v-model="query.StrISBN" />
                  </el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" style="margin-left:10px" icon="el-icon-search" @click="onSubmit">查询</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col>
            <el-form-item prop="title" label="正题名(书名)">
              <el-input v-model="formModel.StrPositiveTitle" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop="title" label="第一责任者">
              <el-input v-model="formModel.StrFirstResponsible" />
            </el-form-item>
          </el-col>
<!--          <el-col>-->
<!--            <el-form-item prop="title" label="ISBN(统一)书号">-->
<!--              <el-input v-model="formModel.StrISBN" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col>
            <el-form-item prop="title" label="单价">
              <el-input v-model="formModel.FloatPricePer" />
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item prop="publish" label="出版社">
              <el-input v-model="formModel.StrPublisher" />
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item prop="place" label="出版地">
              <el-input v-model="formModel.StrPublisherPlace" />
            </el-form-item>
          </el-col>


          <el-col>
            <el-form-item prop="data" label="出版时间">
              <el-input v-model="formModel.StrPublicationDate" />
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item prop="pages" label="页数">
              <el-input v-model="formModel.StrNumberPages" />
            </el-form-item>
          </el-col>
<!--          <el-col>-->
<!--            <el-form-item prop="title" label="书屋位置(书屋位置)">-->
<!--              <el-select v-model="formModel.StrLibraryID" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in swList"-->
<!--                    :key="item.StrID"-->
<!--                    :label="item.StrName"-->
<!--                    :value="item.StrID"-->
<!--                />-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col>
            <el-form-item prop="title" label="分类号">
              <el-input v-model="formModel.StrTypeName" />
            </el-form-item>
          </el-col>


          <el-col v-show="isShow === true">
            <span>共 {{total}} 本图书</span>
          </el-col>

          <el-col>
            <el-form-item>
              <el-button
                  class="filter-item"
                  style="margin-left: 10px;"
                  type="primary"
                  icon="el-icon-edit"
                  @click="handleUpdate"
              >修改</el-button>
            </el-form-item>
          </el-col>


        </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name:'Proofreading',
  data() {
    return {
      isShow:false,
      total:0,
      checked: true,
      labelPosition: 'right',
      formModel: {},
      query: {},
      swList: []
    }
  },
  created() {
    this.getSWList()
  },
  methods: {
    // 获取书屋列表
    getSWList() {
      this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.swList = res.data.msg
        }else{
          this.$message({
            message:res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 提交
    onSubmit() {
      this.$axios({
        url: this.localpath + 'books/isbn/'+this.query.StrISBN,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.formModel = res.data.msg.book
          this.total = res.data.msg.total
          this.isShow = true
        } else {
          this.$notify({
            title: '提示',
            type: 'info',
            message: res.data.msg
          })
        }
      })
    },
    // 编辑
    handleUpdate() {
      if(this.isShow === false)
      {
        return
      }
      ElMessageBox.confirm(
          '确定要对这'+this.total+'本图书进行修改吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {

            this.formModel.FloatPricePer=this.formModel.FloatPricePer.toString()
            this.$axios({
              url: this.localpath + 'books/batchUpdateBookInfo',
              method: 'post',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then(res => {
              if (res.data.ok) {
                this.$message({
                  message:res.data.msg,
                  type: 'success'
                })
                this.getList()
              }else{
                this.$message({
                  message:res.data.msg,
                  type: 'error'
                })
              }
            })
            this.formModel = {}
            this.query = {}
            this.isShow = false
            this.total = 0
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消校对',
            })
          })
    }
  }
}
</script>
<style scoped>

</style>
